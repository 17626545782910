<template>

  <div>
    <v-dialog
        v-model="campaignEmailDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-row>
          <v-col cols="12">
            <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
            >
              {{
                value.addMail && value.addMail.design !== undefined ? 'Neue E-Mail bearbeiten' : ' E-Mail erstellen'
              }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <v-card>
        <v-toolbar
            color="secondary"
            dark
        >
          <v-btn
              dark
              icon
              @click="campaignEmailDialog=false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Kampagnen-E-Mail erstellen</v-toolbar-title>

        </v-toolbar>

        <v-card-text v-if="value.addMail !== undefined">
          <EditForm ref="addMailForm" v-model="value.addMail" :model="addMailModel" :parent="value"/>

          <v-dialog
              v-model="editorDialog"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-col cols="12">
                <v-btn
                    v-if="value.addMail && value.addMail.emailID !== undefined"
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                >
                  {{
                    value.addMail && value.addMail.design === undefined ? 'Template Editor starten' : 'E-Mail-Design anpassen'
                  }}
                </v-btn>
                <v-btn
                    v-if="value.addMail && value.addMail.design !== undefined"
                    color="primary"
                    dark
                    @click="resetAddMail"
                >
                  E-Mail-Design löschen und Template wählen
                </v-btn>
              </v-col>
            </template>
            <v-card>
              <v-toolbar
                  color="secondary"
                  dark
              >
                <v-btn
                    dark
                    icon
                    @click="closeEditor"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>E-Mail Template editieren</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn
                      dark
                      text
                      @click="saveAddMailLayout()"
                  >
                    Design übernehmen
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>

              <TemplateEditor :key="value.addMail.emailID" :ref="'addMailEditor'"
                              :design="value.addMail.design" :fields="{}" :files="value.uploads"
                              :templateID="value.addMail.emailID"/>

            </v-card>
          </v-dialog>
          <v-col>
            <v-alert v-if="doubledSubject" type="error">Dieser Betreff ist schon einmal verwendet.</v-alert>
            <v-btn :disabled="createAddMailDisabled" color="success" @click="createAddMail">Bearbeitung
              übernehmen
            </v-btn>
          </v-col>
        </v-card-text>

        <!--                    <MainList v-if="campaignWorkDialog" :key="value.id" :campaign="value"
                                      :campaignModel="model"
                                      :isLoggedIn="true" route-name="Vereine"/>-->

      </v-card>
    </v-dialog>

    <v-dialog
        v-if="value.settings && Array.isArray(value.settings.followUpMails) && value.settings.followUpMails.length > 0"
        v-model="existingMailsDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-row>
          <v-col cols="12">
            <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
            >
              E-Mails bearbeiten
            </v-btn>
          </v-col>
          <v-col>
            <v-alert v-if="exisitingEmailToSave" type="warning">Die Änderung der E-Mail wurde noch nicht für die
              Kampagne
              gespeichert. Klicken Sie unten rechts auf Speichern, um die Änderungen zu speichern.
            </v-alert>
          </v-col>
        </v-row>
      </template>
      <v-card>
        <v-toolbar
            color="secondary"
            dark
        >
          <v-btn
              dark
              icon
              @click="existingMailsDialog=false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Kampagnen-E-Mails bearbeiten</v-toolbar-title>

        </v-toolbar>

        <v-card-text>
          <v-tabs
              v-model="tab"
          >
            <v-tab
                v-for="tabNav in tabNames"
                :key="tabNav"
            >
              {{ tabNav }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item
                v-for="(mail,i) in value.settings.followUpMails"
                :key="'tab'+i+mail.subject"
            >
              <v-card
                  flat
              >
                <v-card-text>
                  <EditForm :key="'form'+i" ref="editMailForm" v-model="currentMail" :model="addMailModel"
                            :parent="value"/>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>


          <v-dialog
              :key="'currentDialog'+tab"
              v-model="currentDialog"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-col cols="12">
                <v-btn
                    v-if="currentMail.emailID !== undefined"
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                >
                  E-Mail-Design anpassen
                </v-btn>
              </v-col>
            </template>
            <v-card>
              <v-toolbar
                  color="secondary"
                  dark
              >
                <v-btn
                    dark
                    icon
                    @click="currentDialog=false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>E-Mail Template editieren</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn
                      dark
                      text
                      @click="saveAddMailLayout(tab)"
                  >
                    Design übernehmen
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>

              <TemplateEditor :key="currentMail.emailID" :ref="'addMailEditor'+tab"
                              :design="currentMail.design" :fields="{}" :files="value.uploads"
                              :templateID="currentMail.emailID"/>

            </v-card>
          </v-dialog>
          <v-col>
            <v-btn color="accent" @click="deleteMail(tab)">E-Mail löschen
            </v-btn>
            <v-btn :disabled="currentMail.html === '' || doubledSubject" color="success" @click="saveMail(tab)">
              Bearbeitung
              übernehmen
            </v-btn>
          </v-col>
          <v-row>
            <v-col>
              <v-alert v-if="doubledSubject" :key="'d'+currentMail.subject" type="error">Dieser Betreff ist schon einmal
                verwendet.
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>

        <!--                    <MainList v-if="campaignWorkDialog" :key="value.id" :campaign="value"
                                      :campaignModel="model"
                                      :isLoggedIn="true" route-name="Vereine"/>-->

      </v-card>
    </v-dialog>
    <v-row>
      <v-col>
        <v-alert v-if="dataToSave" type="warning">Die Änderungen der Kampagne sind noch nicht gespeichert. Bitte nutzen
          Sie die Schaltfläche Speichern (unten rechts), um die Kampagne zu aktualisieren.
        </v-alert>
      </v-col>
    </v-row>

  </div>


</template>
<script>
import TemplateEditor from "@/components/pages/TemplateEditor";

export default {
  name: 'FollowUpMailEditor',
  components: {
    TemplateEditor,
    EditForm: () => import('@/components/Forms/EditForm'),
  },
  props: {
    value: {
      required: true,
      type: [Object, Array]
    },
  },
  data() {
    return {
      originalItem: this.$props.value.settings ?
          Object.assign({}, this.$props.value, this.$props.value.settings.followUpMails)
          :
          Object.assign({}, this.$props.value)
      ,
      tab: null,
      currentDialog: false,
      existingMailsDialog: false,
      currentMail: {
        design: {},
        html: "",
        emailID: null
      },
      newEmailToSave: false,
      exisitingEmailToSave: false,
      campaignEmailDialog: false,
      editorDialog: false
    };
  },
  computed: {
    dataToSave() {
      let oItem = Object.assign({}, this.originalItem, this.originalItem.followUpMails);
      delete oItem.addMail;
      let newItem = this.$props.value.settings ?
          Object.assign({}, this.$props.value, this.$props.value.settings.followUpMails)
          : Object.assign({}, this.$props.value);
      delete newItem.addMail;

      return JSON.stringify(oItem) !== JSON.stringify(newItem);
    },
    doubledSubject() {
      return (
              this.currentMail !== undefined &&
              this.tab !== null && this.tabNames.indexOf(this.currentMail.subject) !== this.tab)
          || new Set(this.tabNames).size !== this.tabNames.length
          || this.$props.value.addMail !== undefined
          && this.tabNames.indexOf(this.$props.value.addMail.subject) !== -1;
    },
    createAddMailDisabled() {
      //return this.$refs.addMailForm !== undefined && !this.$refs.addMailForm[0].valid || this.value.addMail.emailHTML === undefined
      return this.$props.value.addMail === undefined || this.$props.value.addMail.emailHTML === undefined || this.$props.value.addMail.emailHTML === '' || this.doubledSubject
    },
    tabNames() {
      if (!Array.isArray(this.value.settings.followUpMails)) {
        return [];
      }
      return this.value.settings.followUpMails.map(fMail => fMail.subject);
    },
    addMailModel() {
      return {
        scope: {
          label: "Datenquelle",
          type: "select",
          options(item, parent) {

            return [
              {
                d: `Kampagnen-Empfänger (${parent.dataSource})`,
                v: parent.dataSource
              }
            ];
            //disabled as backend is not capable to send campaign input emails scheduled
            /*if(parent.hasForm === true){
              sourceOptions.push({
                d: `Kampagnen-Eingaben`,
                v: "campaignsInputs"
              });
            }
            if(parent.hasForm === true && parent.settings.modules.Veranstaltung && parent.settings.modules.Veranstaltung.scope === 'campaignsInputsAddable'){
              sourceOptions.push({
                d: `Kampagnen-Eingaben (${parent.settings.modules.Veranstaltung.scopeKey})`,
                v: "campaignsInputsAddable"
              });
            }*/
            //return sourceOptions;
          }
        },
        "start": {
          "type": "datetime",
          "label": "Startzeit",
          "required": true,
          "rules": "notEmptyRules",
          "tooltip": "Startzeit der E-Mail.\nLiegt diese in der Vergangenheit wird beim nächsten Durchlauf versendet. \n"
        },
        "subject": {
          "type": "text",
          "required": true,
          "label": "E-Mail-Betreff"
        },
        "recipient": {
          "type": "field",
          "required": true,
          "label": "E-mail-Adresse Empfänger (<%=user.email%>)",
          "default": "<%=user.email%>"
        },
        "emailID": {
          "query": true,
          "listName": "mailTemplates",
          "model": {
            "id": false,
            "design": "Design",
            "name": "Name"
          },
          "reference": true,
          "change": "(x)=>{delete x.design; delete x.emailHTML;this.validate()}",
          "value": "id",
          "label": "E-Mail-Template",
          "type": "select",
          "short": "name",
          "long": "name",
          "required": true,
          condition(x) {
            return x.scope !== undefined
          }
        }
      };
    },
  },
  watch: {
    tab() {
      this.currentMail = {
        ...this.value.settings.followUpMails[this.tab]
      };
    },
    campaignEmailDialog() {
      if (this.$props.value.addMail === undefined) {
        this.$set(this.$props.value, 'addMail', {
          design: undefined,
          emailHTML: undefined
        });
      }
    },
  },
  methods: {
    closeEditor() {
      this.editorDialog = false;
      //this.$refs.emailEditor.shift();
    },
    deleteMail(i) {
      this.$props.value.settings.followUpMails.splice(i, 1);
      if (this.$props.value.settings.followUpMails.length === 0) {
        this.editorDialog = false;
        //check if other module has email
        for (let module in this.$props.value.settings.modules) {
          if (this.$props.value.settings.modules[module].confirmationEmail === true) {
            this.$props.value.hasEmails = true;
          }
          if (['Start-E-Mail', 'Reminder-E-Mail', 'Rechnungs-E-Mail', 'Zahlungserinnerung'].indexOf(module) !== -1) {
            this.$props.value.hasEmails = true;
          }
        }
      }
    },
    saveAddMailLayout(i) {
      let mailEditor;
      //existingMail
      if (i !== undefined && !isNaN(i)) {
        mailEditor = Array.isArray(this.$refs['addMailEditor' + i])
            ?
            this.$refs['addMailEditor' + i][0]
            :
            this.$refs['addMailEditor' + i]

        ;
        mailEditor.export((dh) => {
          this.$set(this.currentMail, 'design', dh.design);
          this.$set(this.currentMail, 'emailHTML', dh.html);
          this.currentDialog = false;
          //that.validate(that.$refs[name + '-form'][0].validate(),name);
          //that.$refs.emailEditor.shift();
        });
      } else {
        mailEditor = Array.isArray(this.$refs.addMailEditor)
            ?
            this.$refs.addMailEditor[0]
            :
            this.$refs.addMailEditor

        ;
        mailEditor.export((dh) => {
          this.$set(this.$props.value.addMail, 'design', dh.design);
          this.$set(this.$props.value.addMail, 'emailHTML', dh.html);
          this.editorDialog = false;
          //that.validate(that.$refs[name + '-form'][0].validate(),name);
          //that.$refs.emailEditor.shift();
        });
      }
    },
    resetAddMail() {
      this.$props.value.addMail.design = undefined;
      this.$props.value.addMail.emailHTML = undefined;
      this.newEmailToSave = false;
    },
    saveMail(i) {
      this.$props.value.settings.followUpMails[i] = {
        ...this.currentMail
      };
      this.exisitingEmailToSave = true;
      this.$props.value.hasEmails = true;
      this.existingMailsDialog = false;
    },
    createAddMail() {
      if (this.$props.value.settings.followUpMails === undefined) {
        this.$set(this.$props.value.settings, 'followUpMails', [{...this.$props.value.addMail}]);
      } else {
        this.$props.value.settings.followUpMails.push({...this.$props.value.addMail});
      }
      this.$props.value.hasEmails = true;
      this.newEmailToSave = true;
      this.campaignEmailDialog = false;
      this.existingMailsDialog = false;
      this.$props.value.addMail = {};
    },
  }
}

</script>